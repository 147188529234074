import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontSize: 64,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h2: {
      fontSize: 48,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h3: {
      fontSize: 32,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h5: {
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h6: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: "#E5E5E5",
          backgound: "white",
        },
        elevation1: { boxShadow: "none", border: `1px solid #E5E5E5` },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: "9.5px",
          paddingBottom: "9.5px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: "2.5px !important",
          paddingBottom: "2.5px !important",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        // Multiline Text Areas
        inputMultiline: { padding: "0px !important" },
      },
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
