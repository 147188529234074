import { ReactNode, useEffect, useState } from "react";
import Unauthorized from "pages/Errors/Unauthorized";
import { getMyAgency } from "api/agency";

interface APIGuardProps {
  children?: ReactNode;
}

const AuthGuard = ({ children }: APIGuardProps) => {
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState<null | boolean>(null);
  const params = new URLSearchParams(window.location.search);
  const apiKey = params.get("apiKey");

  const verifyAccess = async () => {
    if (apiKey) {
      setLoading(true);
      window.localStorage.setItem("api-key", apiKey);
      try {
        await getMyAgency();
        setLoading(false);
        setAccess(true);
      } catch (e) {
        setLoading(false);
        setAccess(false);
      }
    } else {
      window.localStorage.removeItem("api-key");
      setAccess(false);
    }
  };

  useEffect(() => {
    verifyAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (access === null || loading) return;
  if (access === false) return <Unauthorized />;

  return <>{children}</>;
};

export default AuthGuard;
