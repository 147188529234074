import axios from "axios";

import { getAPIKey } from "./token-manager";

const { REACT_APP_API_URL } = process.env;

const apiClient = axios.create({
  baseURL: REACT_APP_API_URL,
});

apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = await getAPIKey();

    if (apiKey && config.headers) {
      config.headers["x-access-token"] = apiKey;
    }

    return config;
  }
  // error => Promise.reject(error),
);

export default apiClient;
