import { Fragment, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ApiGuard from "./ApiGuard";

type RoutesTypes = {
  exact?: boolean;
  path: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesTypes;
}[];
export const renderRoutes = (routes: RoutesTypes = []): JSX.Element => (
  <Routes>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Layout = route.layout || Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          element={
            <Guard>
              <Layout>
                {route.routes ? renderRoutes(route.routes) : <Component />}
              </Layout>
            </Guard>
          }
        />
      );
    })}
    <Route path="*" element={<Navigate to="/404" replace />} />
  </Routes>
);

const routes: RoutesTypes = [
  {
    path: "/404",
    component: lazy(() => import("pages/Errors/NotFound")),
  },
  {
    path: "/contact",
    guard: ApiGuard,
    component: lazy(() => import("pages/ContactForm")),
  },
  {
    path: "/estimation",
    guard: ApiGuard,
    component: lazy(() => import("pages/EstimationPage")),
  },
];

export default routes;
